import React from "react";
import { useLocation } from '@reach/router';
import { useLocalization, LocalizedLink as Link } from "gatsby-theme-i18n";
import { useIntl } from "react-intl";
import { StaticImage } from "gatsby-plugin-image";
import "../styles/help-menu.less";

const HelpMenu = () => {
    const intl = useIntl();
    const { locale } = useLocalization(); 
    
 

    return (        
        <>            
            <p className="title">GENERAL ISSUES</p>
            <p><Link to="/quick-start">Quick Start</Link></p>
            <div className="registration">
                <p>{intl.formatMessage({ id: "helpmenu.registration" })}</p>
                <ul>                    
                    <li><Link to="/create-swisscows-email-account" activeClassName="active">{intl.formatMessage({ id: "helpmenu.registration.question1" })}</Link></li>
                    <li><Link to="/add-swisscows-email-in-apple-mail" activeClassName="active">{intl.formatMessage({ id: "helpmenu.registration.question2" })}</Link></li>
                    <li><Link to="/add-swisscows-email-in-outlook" activeClassName="active">{intl.formatMessage({ id: "helpmenu.registration.question3" })}</Link></li>
                    <li><Link to="/add-swisscows-email-to-android-outlook" activeClassName="active">{intl.formatMessage({ id: "helpmenu.registration.question4" })}</Link></li>
                </ul>
            </div>

            <div className="settings">
                <p>{intl.formatMessage({ id: "helpmenu.settings" })}</p>
                <ul>
                    <li><Link to="/question5" activeClassName="active">Signatur in die E-Mail hinzufügen</Link></li>
                    <li><Link to="/question6" activeClassName="active">Ordner anlegen</Link></li>
                    <li><Link to="/question7" activeClassName="active">Mailing-Liste anlegen</Link></li>
                    <li><Link to="/question8" activeClassName="active">SMTP und IMAP Einstellung</Link></li>
                </ul>
            </div>

            <div className="security">
                <p>{intl.formatMessage({ id: "helpmenu.security" })}</p>
                <ul>
                    <li><Link to="/question9" activeClassName="active">Was ist PGP?</Link></li>
                    <li><Link to="/question10" activeClassName="active">PGP-Verschlüsselung in Swisscows.email erstellen</Link></li>
                    <li><Link to="/question11" activeClassName="active">Austausch öffentlicher PGP-Schlüssel</Link></li>
                    <li><Link to="/question12" activeClassName="active">Erstellen einer verschlüsselten Nachricht</Link></li>
                    <li><Link to="/question13" activeClassName="active">Was ist eine Nachrichtensignatur</Link></li>
                    <li><Link to="/question14" activeClassName="active">Erstellen einer Nachrichtensignatur</Link></li>
                    <li><Link to="/question15" activeClassName="active">Verschlüsselungseinstellungen</Link></li>
                </ul>
            </div>
        </>
    );
}

export default HelpMenu;